@font-face {
  font-family: "NanumSquareAcr";
  font-style: normal;
  font-weight: normal;
  src: url("./NanumSquareOTF.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "NanumSquareAcr";
  font-style: normal;
  font-weight: 800;
  src: url("./NanumSquareOTF_acB.otf") format("opentype");
  font-display: swap;
}
